import * as z from 'zod'
import { isEmptyString } from '@digital-magic/ts-common-utils'
import { ZodCustomErrorCode } from '@api/errors'
import { EnginePowerSchema, EngineVolumeSchema, FileTypeSchema } from '@api/endpoints'
import { BuyoutCommissionRequestSchema, FinancialObligationSchema } from '@api/endpoints/forms'

export const BuyoutCommissionFormSchema = BuyoutCommissionRequestSchema.omit({ imageIds: true }).extend({
  engineVolCm3: EngineVolumeSchema.optional().or(z.literal('')),
  engineKw: EnginePowerSchema.optional().or(z.literal('')),
  nextInspectionDate: z.date().nullable(),
  imageFiles: FileTypeSchema.array()
})
export type BuyoutCommissionFormValues = z.infer<typeof BuyoutCommissionFormSchema>

export const ConsentPersonalDataSchema = z
  .boolean()
  .optional()
  .superRefine((v, ctx) => {
    if (!v) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          customCode: ZodCustomErrorCode.ConsentRequired
        }
      })
    }
  })
export type ConsentPersonalData = z.infer<typeof ConsentPersonalDataSchema>

export const EmptyStringValueSchema = z.literal('')
export type EmptyStringValue = z.infer<typeof EmptyStringValueSchema>

export const RequiredEmptyStringValueSchemaRequired = EmptyStringValueSchema.superRefine((v, ctx) => {
  if (isEmptyString(v)) {
    return ctx.addIssue({
      code: z.ZodIssueCode.invalid_type,
      expected: z.ZodParsedType.unknown,
      received: z.ZodParsedType.unknown
    })
  }
})

export const FinancialObligationFormSchema = FinancialObligationSchema.extend({
  amountOrLimit: FinancialObligationSchema.shape.amountOrLimit.or(RequiredEmptyStringValueSchemaRequired),
  financer: FinancialObligationSchema.shape.financer.or(RequiredEmptyStringValueSchemaRequired),
  monthlyPayment: FinancialObligationSchema.shape.monthlyPayment.or(RequiredEmptyStringValueSchemaRequired),
  obligationType: FinancialObligationSchema.shape.obligationType.or(RequiredEmptyStringValueSchemaRequired)
})
export type FinancialObligationFormValues = z.infer<typeof FinancialObligationFormSchema>

export const FormWithObligationsSchema = z.object({
  obligations: FinancialObligationFormSchema.array()
})

export type FormWithObligationsValues = z.infer<typeof FormWithObligationsSchema>
